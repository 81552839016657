export const linksList = [
  {
    name: 'about',
    path: '/info/about-us',
    labelTranslationKey: 'Label.AboutUs'
  },
  {
    name: 'jobs',
    path: '/info/jobs',
    labelTranslationKey: 'Label.Jobs'
  },
  {
    name: 'blog',
    path: '/info/blog',
    labelTranslationKey: 'Label.sBlog'
  },
  {
    name: 'parents',
    path: '/info/parents',
    labelTranslationKey: 'Label.Parents'
  },
  {
    name: 'help',
    path: '/info/help',
    labelTranslationKey: 'Label.Help'
  },
  {
    name: 'terms',
    path: '/info/terms',
    labelTranslationKey: 'Label.Terms'
  },
  {
    name: 'privacy',
    path: '/info/privacy',
    labelTranslationKey: 'Label.Privacy',
    cssClass: 'privacy'
  }
];

export const linksListWithGiftCardLabel = [
  {
    name: 'about',
    path: '/info/about-us',
    labelTranslationKey: 'Label.AboutUs'
  },
  {
    name: 'jobs',
    path: '/info/jobs',
    labelTranslationKey: 'Label.Jobs'
  },
  {
    name: 'blog',
    path: '/info/blog',
    labelTranslationKey: 'Label.sBlog'
  },
  {
    name: 'parents',
    path: '/info/parents',
    labelTranslationKey: 'Label.Parents'
  },
  {
    name: 'giftcards',
    path: '/giftcards',
    labelTranslationKey: 'Label.GiftCards',
    cssClass: 'giftcards'
  },
  {
    name: 'help',
    path: '/info/help',
    labelTranslationKey: 'Label.Help'
  },
  {
    name: 'terms',
    path: '/info/terms',
    labelTranslationKey: 'Label.Terms'
  },
  {
    name: 'privacy',
    path: '/info/privacy',
    labelTranslationKey: 'Label.Privacy',
    cssClass: 'privacy'
  }
];

export const infoTabHash = '#!/info';
